import React, {useEffect, useRef, useState} from "react";
import EmployeeModel from "../../../model/types/basistypes/ressources/EmployeeModel";
import TeamModel from "../../../model/types/TeamModel";
import ConstructionSiteModel from "../../../model/types/basistypes/ressources/ConstructionSiteModel";
import {getConstructionSiteAsOfDate} from "../../../model/ModelController/Resources/ConstructionSiteController";
import TaskModel from "../../../model/types/basistypes/ressources/tasks/TaskModel";
import {getValidTasksAtDateForConstructionSite} from "../../../model/ModelController/Resources/tasks/TaskController";
import DnDContextWrapper, {createRegisterCallBack, DnDItem} from "./DndContextWrapper";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Dialog,
	Grid,
	MenuItem,
	Paper,
	Typography
} from "@mui/material";
import {
	GlobalStyledDivider,
	GlobalStyledPaper,
	GlobalStyledTextField
} from "../../StyledComponents/GlobalStyled/GlobalStyled";
import TeamWorkPlanPDF from "./TeamDialogHelper/TeamWorkPlanPDF";
import FullScreenDialogSidebar from "../../Administration/Resources/Helper/FullScreenDialogSidebar";

import {ExpandMore} from "@mui/icons-material";
import VehicleModel from "../../../model/types/basistypes/ressources/VehicleModel";
import EquipmentModel from "../../../model/types/basistypes/ressources/EquipmentModel";
import DraggableTaskListByConstructionSites from "./TeamDialogHelper/DraggableTaskListByConstructionSites";
import {useTranslation} from "react-i18next";
import {localDateToUTCTimestamp, utcTimestampToFormattedString} from "../../../utility/dateUtil";

interface propsType {
	team: TeamModel;
	index: number;
	open: boolean;
	workPlanDate: number;

	handleClose(): void;

	handleSave(Team: TeamModel, index: number): any;
}

export default function TeamDialog(props: propsType) {
	const [localTeam, setLocalTeam] = useState<TeamModel>(props.team);
	const [constructionSiteList, setConstructionSiteList] = useState<ConstructionSiteModel[]>([]);
	const [availableTasks, setAvailableTasks] = useState<TaskModel[]>([]);
	const [dndRegister, setDndRegister] = useState<Map<string, DnDItem>>(new Map<string, DnDItem>());
	const registerToDnDDRegister = createRegisterCallBack(dndRegister, setDndRegister);
	const { t } = useTranslation();

	let localTeamRef = useRef(localTeam);

	const updateTeam = (team: TeamModel) => {
		localTeamRef.current = team;
		setLocalTeam(team);
	};

	//initialize the local copy of the team based on the properties
	useEffect(() => {
		updateTeam(props.team);
	}, [props.index, props.team]);

	//initialize constructionSites with today's valid construction Sites
	// since some may already be assigned to our site, we filter out the double ones based on their id.
	// the ones in the team are kept.
	useEffect(() => {
		getConstructionSiteAsOfDate(props.workPlanDate).then((constructionSites) => {
			if (constructionSites) {
				setConstructionSiteList(constructionSites);
			}
		});
	}, [props.workPlanDate]);

	//Fetch Tasks based on the assigned Consturction Sites
	useEffect(() => {
		getValidTasksAtDateForConstructionSite(props.workPlanDate, constructionSiteList)
			.then((tasks) => {
				if (tasks) {
					setAvailableTasks(tasks);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, [constructionSiteList, props.workPlanDate]);

	const handleChangeForeman = (foreman: EmployeeModel) => {
		return () => {
			updateTeam({
				...localTeamRef.current,
				foremans: [foreman],
			});
		};
	};

	return (
		<Dialog open={props.open} keepMounted fullScreen PaperProps={{ sx: { backgroundColor: "paper.dark" } }} aria-describedby="alert-dialog-slide-description">
			{
				// @ts-ignore
				<DnDContextWrapper registeredItems={dndRegister}>
					<Grid container xs={12}>
						{/*Highest grid container*/}
						<Grid item xs={12}>
							<FullScreenDialogSidebar
								iconBackAction={props.handleClose}
								iconSaveAction={props.handleSave(localTeamRef.current, props.index)}
								printDocument={<TeamWorkPlanPDF teams={[localTeamRef.current]}
																date={localDateToUTCTimestamp(props.workPlanDate)}/>}
								printFileName={t("teamWorkPlanPDF.fileName") + utcTimestampToFormattedString(props.workPlanDate) + ".pdf"}
								iconPrintAction={() => {
								}}
								title={t("team.manageTitle")}
							/>
						</Grid>
						<Grid item xs={7}>
							<GlobalStyledPaper>
								<Grid item xs={12}>
									{/*Team Information Paper Container
                                Team information header*/}
									<Grid item xs={12}>
										<Typography variant={"h5"} align={"center"}>
											Team-ID:{"  "}
											{localTeamRef.current?.name || props.team.id}
										</Typography>
										<GlobalStyledDivider/>
									</Grid>
									<Grid item container xs={12} spacing={2} p={2}>
										{/*Team information body*/}
										<Grid item xs={6}>
											<Accordion disableGutters>
												<AccordionSummary expandIcon={<ExpandMore/>}>
													<Typography variant={"h6"}>{t("team.information")}</Typography>
												</AccordionSummary>
												<AccordionDetails sx={{m: 0, p: 0.5}}>
													<Grid item container xs={12}>
														<Grid item xs={12}>
															<GlobalStyledTextField
																id="outlined-select-currency"
																label={t("team.name")}
																value={localTeamRef.current?.name || ""}
																onChange={(e) => {
																	updateTeam({
																		...localTeamRef.current,
																		name: e.currentTarget.value
																	});
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<GlobalStyledTextField id="outlined-select-foreman" select
																				   label={t("foreman.title")}
																				   value={localTeamRef.current?.foremans[0]?.id}>
																{props.team.employees.map((option) => (
																	<MenuItem key={option.id} value={option.id}
																			  onClick={handleChangeForeman(option)}>
																		<Typography
																			variant={"subtitle2"}>{option.surname + ", " + option.name}</Typography>
																	</MenuItem>
																))}
															</GlobalStyledTextField>
														</Grid>
													</Grid>
												</AccordionDetails>
											</Accordion>
										</Grid>
										<Grid item xs={6}>
											{/* Team Mitarbeiter */}
											<Accordion disableGutters>
												<AccordionSummary expandIcon={<ExpandMore />}>
													<Typography variant={"h6"}>{t("employee.titlePlural") + (props.team.employees.length > 0 ? " (" + props.team.employees.length + ") " : "")}:</Typography>
												</AccordionSummary>
												<AccordionDetails sx={{ m: 0, p: 0.5 }}>
													<Grid item container xs={12}>
														{props.team.employees.map((value: EmployeeModel, index: number) => (
															<Grid item xs={6}>
																<Paper sx={{ m: 1 / 2, p: 1 / 2 }}>
																	<Grid item container xs={12} alignItems={"center"}>
																		<Grid item xs={3}>
																			<Avatar
																				sx={{
																					backgroundColor: "secondary.main",
																					height: 30,
																					width: 30,
																				}}
																			></Avatar>
																		</Grid>
																		<Grid item xs={9}>
																			<Typography variant={"body2"}> {value.name + "," + value.surname}</Typography>
																		</Grid>
																	</Grid>
																</Paper>
															</Grid>
														))}
													</Grid>
												</AccordionDetails>
											</Accordion>
										</Grid>
										<Grid item xs={6}>
											{/* Team Fahrzeuge */}
											<Accordion disableGutters>
												<AccordionSummary expandIcon={<ExpandMore />}>
													<Typography variant={"h6"}>{t("vehicle.titlePlural") + (props.team.vehicles.length > 0 ? " (" + props.team.vehicles.length + ") " : "")}:</Typography>
												</AccordionSummary>
												<AccordionDetails sx={{ m: 0, p: 0.5 }}>
													<Grid item container xs={12}>
														{props.team.vehicles.map((value: VehicleModel, index: number) => (
															<Grid item xs={6}>
																<Paper sx={{ m: 1 / 2, p: 1 / 2 }}>
																	<Grid item container xs={12} alignItems={"center"}>
																		<Grid item xs={3}>
																			<Avatar
																				sx={{
																					backgroundColor: "secondary.main",
																					height: 30,
																					width: 30,
																				}}
																			></Avatar>
																		</Grid>
																		<Grid item xs={9}>
																			<Typography variant={"body2"}> {value.licensePlace}</Typography>
																		</Grid>
																	</Grid>
																</Paper>
															</Grid>
														))}
													</Grid>
												</AccordionDetails>
											</Accordion>
										</Grid>
										<Grid item xs={6}>
											{/* Team Geräte */}
											<Accordion disableGutters>
												<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
													<Typography variant={"h6"}>{t("equipment.titlePlural") + (props.team.equipment.length > 0 ? " (" + props.team.equipment.length + ") " : "")}:</Typography>
												</AccordionSummary>
												<AccordionDetails sx={{ m: 0, p: 0.5 }}>
													<Grid item container xs={12}>
														{props.team.equipment.map((value: EquipmentModel, index: number) => (
															<Grid item xs={6}>
																<Paper sx={{ m: 1 / 2, p: 1 / 2 }}>
																	<Grid item container xs={12} alignItems={"center"}>
																		<Grid item xs={3}>
																			<Avatar
																				sx={{
																					backgroundColor: "secondary.main",
																					height: 30,
																					width: 30,
																				}}
																			></Avatar>
																		</Grid>
																		<Grid item xs={9}>
																			<Typography variant={"body2"}> {value.producer + "[" + value.type + "]"}</Typography>
																		</Grid>
																	</Grid>
																</Paper>
															</Grid>
														))}
													</Grid>
												</AccordionDetails>
											</Accordion>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={12}>
										<Typography variant={"h6"} align={"center"}>
											{t("constructionSite.titlePlural") + " :"}
										</Typography>
										<GlobalStyledDivider />
									</Grid>
									<Grid item xs={12}>
										<DraggableTaskListByConstructionSites
											idDND={"Assigned_Tasks"}
											tasks={localTeam.tasks}
											registerDnDCallback={registerToDnDDRegister}
											referenceUpdateCallBack={(tasks) => {
												/*instead of assigning construction sites via DnD we determine the construction sites based on the tasks
												 *during DnD actions because all connstruction sites were shown on the right anyways this doubles the room on the right
												 *and left */
												const newConstructionSites: ConstructionSiteModel[] = [];
												tasks.forEach((task) => {
													if (newConstructionSites.findIndex((site) => site.id === task.constructionSite.id) === -1) {
														newConstructionSites.push(task.constructionSite);
													}
												});

												updateTeam({
													...localTeamRef.current,
													tasks: tasks,
													constructionSites: newConstructionSites,
												});
											}}
										/>
									</Grid>
								</Grid>
							</GlobalStyledPaper>
						</Grid>
						<Grid item xs={5}>
							{/*offene Aufgaben und Baustellen Grid*/}
							<GlobalStyledPaper>
								<Grid item xs={12}>
									<Typography variant={"h6"} align={"center"}>
										{t("openConstructionSites") + " :"}
									</Typography>
									<GlobalStyledDivider />
								</Grid>
								<Grid item xs={12}>
									<DraggableTaskListByConstructionSites
										idDND={"Bucket_Tasks"}
										tasks={availableTasks}
										registerDnDCallback={registerToDnDDRegister}
										referenceUpdateCallBack={(tasks) => {
											setAvailableTasks(tasks);
										}}
									/>
								</Grid>
							</GlobalStyledPaper>
						</Grid>
					</Grid>
				</DnDContextWrapper>
			}
		</Dialog>
	);
}
