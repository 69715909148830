import VehicleModel from "../../types/basistypes/ressources/VehicleModel";
import {getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import firebase from "firebase";
import DayPlanModel from "../../types/DayPlanModel";
import {purgeUiOnlyFields, resolveNestedDocumentList, transformArrayToReference} from "../FirebaseConverter";

const addVehicle = async (vehicle: VehicleModel) => {
    vehicle = purgeUiOnlyFields(vehicle);

    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("Vehicle")
            .collection("Vehicle")
            .doc(vehicle.id.toString()).set({
            ...vehicle,
            drivingLicenseClasses: await transformArrayToReference(firebase, dbConnection, companyId, "DrivingLicenseClass", vehicle.drivingLicenseClasses),
            usable: true
        });
    }
}

const deleteVehicle = async (vehicle: VehicleModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("Vehicle")
            .collection("Vehicle")
            .doc(vehicle.id.toString())
            .update({usable: false});
    }
}

const resolveVehicle = async (doc: any) => {
    let vehicle: VehicleModel = doc.data()
    let array: firebase.firestore.DocumentReference[] = []
    if (typeof vehicle.drivingLicenseClasses === typeof array) {
        vehicle.drivingLicenseClasses = await resolveNestedDocumentList(vehicle.drivingLicenseClasses);
    }
    return vehicle as VehicleModel;
}


const getVehiclesForDayPlan = async (dayPlan: DayPlanModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let vehicleIds: number[] = [];
        dayPlan.teams.forEach((team, index) => {
            team.vehicles.forEach((vehicle, index) => {
                vehicleIds.push(vehicle.id)
            })
        })
        /* We cannot use "not-in" because it's limited to 10 elements
         * since we cannot make a union of not-ins( would result in all documents)
         * we need to fetch all Vehicles and filter afterwards.
         */
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("Vehicle")
            .collection("Vehicle")
            .where("usable", "==", true).get()

        let promiseArray: Promise<VehicleModel>[] = query.docs.map(async (doc) => {
            return await resolveVehicle(doc)
        });
        return (await Promise.all(promiseArray)).filter(vehicle => !vehicleIds.find(id => id === vehicle.id));
    }
    return []
}

const getVehicleDocumentId = async (id: number) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId) {
        return dbConnection
            .collection(companyId)
            .doc("Vehicle")
            .collection("Vehicle")
            .doc(id.toString())
    }
}

const getVehicle = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("Vehicle")
            .collection("Vehicle")
            .where("usable", "==", true)
            .get()
        let result: VehicleModel[] = [];
        for (let doc of query.docs) {
            result.push(await resolveVehicle(doc))
        }
        return result;
    }
    return []
}

export {
    addVehicle,
    getVehicle,
    deleteVehicle,
    getVehiclesForDayPlan,
    getVehicleDocumentId
}

