import React, {ReactNode, useState} from "react";
import DraggableJSXwrapper from "./DraggableJSXwrapper";
import useTheme from "@mui/material/styles/useTheme";
import {Avatar, Grid, IconButton, List, ListItemButton, Paper, Popover, Typography, useMediaQuery} from "@mui/material";
import {MoreVert,} from "@mui/icons-material";


interface PopOverActions {
    icon: ReactNode,
    actionText: string,
    action: () => void
}

interface propsType {
    title: string,
    id: number,
    index: number,
    referencedObject: any,
    iconAction?: () => void,
    iconDeleteAction?: () => void,
    actions: PopOverActions[]
    children?: React.ReactNode,
    deactivateDnD?: boolean,
    available: boolean,
}

export default function DnDItemCard(props: propsType) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const available = props.available;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAnchor, setModalAnchor] = useState<Element | null>(null);

    const wrapInCloseModal = (action: () => void) => {
        return () => {
            action();
            setModalOpen(false);
        }
    }
    const renderMoreVertIcon = () => {
        const numberOfActions = props.actions.length;

        switch (numberOfActions) {
            case 0:
                return <div/>
            case 1:
                return <Grid item xs={12}>
                    <IconButton disableRipple size={"small"} aria-label="settings" onClick={props.actions[0].action}>
                        {props.actions[0].icon}
                    </IconButton>
                </Grid>
            default:
                return <Grid item xs={12}>
                    <IconButton disableRipple size={"small"} aria-label="settings" onClick={(event) => {
                        setModalOpen(true);
                        setModalAnchor(event.currentTarget)
                    }}>
                        <MoreVert/>
                    </IconButton>
                    <Popover
                        open={modalOpen}
                        anchorEl={modalAnchor}
                        onClose={() => {
                            setModalOpen(false)
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <List>
                            {
                                props.actions.map(
                                    (item, index) => {
                                        return <ListItemButton onClick={wrapInCloseModal(item.action)} key={index}>
                                            {item.icon}
                                            {item.actionText}

                                        </ListItemButton>
                                    }
                                )
                            }
                        </List>
                    </Popover>
                </Grid>
        }
    }

    let render =
        <Paper sx={(available) ? {m: 1 / 2, backgroundColor: "paper.dark"} : {
            m: 1 / 2,
            background: theme.palette.paper.error
        }}>
            <Grid item xs={12} container justifyContent={"flex-start"} alignItems={"center"}>
                {(matches) ?
                    <Grid item xs={3} container justifyContent={"center"}>
                        <Avatar sx={{backgroundColor: "secondary.main", height: 30, width: 30}}>
                        </Avatar>
                    </Grid> : <div/>
                }
                <Grid item xs={(props.actions.length > 0) ? (matches ? 6 : 9) : (matches ? 9 : 12)} container
                      justifyContent={"flex-start"}>
                    <Typography variant={"body2"}> {props.title}</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent={"flex-start"}>
                    {
                        renderMoreVertIcon()
                    }
                </Grid>
            </Grid>
        </Paper>

    return (!props.deactivateDnD) ?
        <DraggableJSXwrapper Id={props.id} index={props.index}>{render}</DraggableJSXwrapper> : render
}


