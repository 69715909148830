import DayPlanModel from "../../types/DayPlanModel";
import { getFirebase } from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import firebase from "firebase";
import TeamModel from "../../types/TeamModel";
import ConstructionSiteModel from "../../types/basistypes/ressources/ConstructionSiteModel";
import TaskModel from "../../types/basistypes/ressources/tasks/TaskModel";
import { resolveNestedDocumentList, transformArrayToReference } from "../FirebaseConverter";
import { resolveTask } from "../Resources/tasks/TaskController";
import { resolveConstructionSite } from "../Resources/ConstructionSiteController";

const addDayPlan = async (dayPlan: DayPlanModel) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		dbConnection
			.collection(companyId)
			.doc("DayPlan")
			.collection("DayPlan")
			.doc(dayPlan.date.toString())
			.set({ ...dayPlan, teams: await createTeamReference(dayPlan.teams) });
	}
};

const resolveTeam = async (team: TeamModel) => {
	team.foremans = await resolveNestedDocumentList(team.foremans);
	team.employees = await resolveNestedDocumentList(team.employees);
	team.vehicles = await resolveNestedDocumentList(team.vehicles);
	team.equipment = await resolveNestedDocumentList(team.equipment);

	let constructionSitePromise: Promise<ConstructionSiteModel>[] = [];
	let constructionSiteDocument: Promise<any>[] = [];

	for (let constructionSiteUnresolved of team.constructionSites) {
		constructionSiteDocument.push(constructionSiteUnresolved.get());
	}

	for (let constructionSite of await Promise.all(constructionSiteDocument)) {
		constructionSitePromise.push(resolveConstructionSite(constructionSite));
	}

	team.constructionSites = await Promise.all(constructionSitePromise);

	let taskPromise: Promise<TaskModel>[] = [];
	let taskDocument: Promise<any>[] = [];

	for (let taskUnresolved of team.tasks) {
		taskDocument.push(taskUnresolved.get());
	}

	for (let task of await Promise.all(taskDocument)) {
		taskPromise.push(resolveTask(task));
	}

	team.tasks = await Promise.all(taskPromise);
	debugger;
	return team as TeamModel;
};

const createTeamReference = async (teams: TeamModel[]) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();
	let companyId = await getCompanyAffiliation();

	let references: any[] = [];

	if (companyId !== undefined) {
		for (let team of teams) {
			references.push({
				...team,
				foremans: await transformArrayToReference(firebase, dbConnection, companyId, "Employee", team.foremans),
				employees: await transformArrayToReference(firebase, dbConnection, companyId, "Employee", team.employees),
				vehicles: await transformArrayToReference(firebase, dbConnection, companyId, "Vehicle", team.vehicles),
				equipment: await transformArrayToReference(firebase, dbConnection, companyId, "Equipment", team.equipment),
				constructionSites: await transformArrayToReference(firebase, dbConnection, companyId, "ConstructionSite", team.constructionSites),
				tasks: await transformArrayToReference(firebase, dbConnection, companyId, "Task", team.tasks),
			});
		}

		return references;
	}
};

const deleteDayPlan = async (dayPlan: DayPlanModel) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		dbConnection.collection(companyId).doc("DayPlan").collection("DayPlan").doc(dayPlan.date.toString()).delete();
	}
};

const copyDayPlanToDate = async (dayPlan: DayPlanModel, targetDate: number) => {
	let newTeams: TeamModel[] = [];

	dayPlan.teams.forEach((team, index) => {
		let newConstructionSites: ConstructionSiteModel[] = [];
		let newTasks: TaskModel[] = [];

		team.constructionSites.forEach((constructionSite, index) => {
			if (constructionSite.validFrom <= targetDate && constructionSite.validTo >= targetDate) {
				newConstructionSites.push(constructionSite);
			}
		});

		team.tasks.forEach((task, index) => {
			if (task.plannedFromDate <= targetDate && task.plannedToDate >= targetDate) {
				newTasks.push(task);
			}
		});

		newTeams.push({ ...team, tasks: newTasks, constructionSites: newConstructionSites });
	});

	let copy: DayPlanModel = { ...dayPlan, teams: newTeams, date: targetDate };
	addDayPlan(copy);
	return copy;
};

const resolveDayPlan = async (document: any) => {
	let dayPLan: DayPlanModel = document.data();
	const resolvedTeams: Promise<TeamModel>[] = [];
	for (let teamReference of dayPLan.teams) {
		resolvedTeams.push(resolveTeam(teamReference));
	}

	dayPLan.teams = await Promise.all(resolvedTeams);
	return dayPLan as DayPlanModel;
};

const getDayPlansForRange = async (dateFrom: number, dateTo: number) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
			.collection(companyId)
			.doc("DayPlan")
			.collection("DayPlan")
			.where("date", "<=", dateTo)
			.where("date", ">=", dateFrom)
			.get();
		let result: DayPlanModel[] = [];

		for (let doc of query.docs) {
			let TagesPlan = doc.data();
			let Teams: TeamModel;
		}
		for (let doc of query.docs) {
			result.push(await resolveDayPlan(doc));
		}
		return result;
	}
	return [];
};

const getDayPlansForDay = async (date: number) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
			.collection(companyId)
			.doc("DayPlan")
			.collection("DayPlan")
			.where("date", "==", date)
			.get();
		let result: DayPlanModel[] = [];

		for (let doc of query.docs) {
			result.push(await resolveDayPlan(doc));
		}

		return result;
	}
	return [];
};

const getDayPlan = async () => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection.collection(companyId).doc("DayPlan").collection("DayPlan").get();
		let result: DayPlanModel[] = [];

		for (let doc of query.docs) {
			result.push(await resolveDayPlan(doc));
		}

		return result;
	}
};

export { addDayPlan, getDayPlan, deleteDayPlan, copyDayPlanToDate, getDayPlansForDay, getDayPlansForRange };
