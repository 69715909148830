import React, { useContext, useState } from "react";
import CustomerModel from "../../../../model/types/basistypes/ressources/CustomerModel";
import CustomerMask from "./CustomerMask";
import { getCustomer } from "../../../../model/ModelController/Resources/CustomerController";
import { useSnackbar, VariantType, withSnackbar } from "notistack";
import { AppBarInfoAreaContext } from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

function CustomerOverview(this: any) {
	const appbarContext = useContext(AppBarInfoAreaContext);
	const [registered, setRegistered] = useState(false);
	const [isDirtyIndex, setIsDirtyIndex] = useState(-1);
	const [CustomerList, setCustomerList] = useState<CustomerModel[]>([]);
	const [selectedCustomer, setSelectedCustomer] = useState<number>(0);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	appbarContext.updateContent(t("customer.manageTitle"));

	const showSnackbar = (variant: VariantType, message: string) => {
		enqueueSnackbar(message, { variant });
	};

	const updateCallback = (index: number) => {
		return (update: CustomerModel) => {
			update = { ...update, _isNew: false };
			let newState: CustomerModel[] = [...CustomerList];
			newState[index] = update;
			setCustomerList(newState);
			setIsDirtyIndex(-1);
			showSnackbar(
				"success",
				t("Customer.successfullyCreated", {
					name: update.name,
				})
			);
		};
	};

	const deleteCallback = (index: number) => {
		return (update: CustomerModel) => {
			let newState: CustomerModel[] = CustomerList.filter((value, index1) => {
				return index1 !== index;
			});
			setCustomerList(newState);
			setSelectedCustomer(0);
			setIsDirtyIndex(-1);
			showSnackbar(
				"success",
				t("Customer.successfullyDeleted", {
					name: update.name,
				})
			);
		};
	};

	if (!registered) {
		getCustomer().then((result: CustomerModel[] | undefined) => {
			if (result !== undefined) setCustomerList(result);
		});
		setRegistered(true);
	}

	const getEmptyCustomer = (): CustomerModel => {
		return {
			id: Date.now(),
			name: t("customer.placeholder.name"),
			address: {
				address: t("customer.placeholder.address.address"),
				city: t("customer.placeholder.address.city"),
				zipCode: t("customer.placeholder.address.zipCode"),
			},
			contacts: [],
			_isNew: true,
		};
	};

	const addCustomer = () => {
		if (discardChangesInputWindowConfirmDialog()) {
			debugger;
			let newCustomerList = [...CustomerList];
			if (CustomerList[selectedCustomer]?._isNew) {
				newCustomerList.splice(selectedCustomer, 1);
			}

			if (CustomerList === undefined) {
				let newState: CustomerModel[] = [getEmptyCustomer()];
				setCustomerList(newState);
				setSelectedCustomer(newState.length - 1);
				setIsDirtyIndex(-1);
			} else {
				newCustomerList.push(getEmptyCustomer());
				setCustomerList(newCustomerList);
				setSelectedCustomer(newCustomerList.length - 1);
				setIsDirtyIndex(-1);
			}
		}
	};

	const setIndex = (resource: any, index: number) => {
		if (selectedCustomer === index) {
			return;
		}
		if (discardChangesInputWindowConfirmDialog()) {
			if (CustomerList[selectedCustomer]?._isNew) {
				let newCustomerList = [...CustomerList];
				newCustomerList.splice(selectedCustomer, 1);
				setSelectedCustomer(index);
				setCustomerList(newCustomerList);
			}
			setSelectedCustomer(index);
			setIsDirtyIndex(-1);
		} else {
			//do nothing -> stay on old selected resource
		}
	};

	const discardChangesInputWindowConfirmDialog = () => {
		if (isDirtyIndex !== -1 || CustomerList[selectedCustomer]?._isNew) {
			return window.confirm(t("discardChangesDialog.text"));
		} else {
			return true;
		}
	};

	return (
		<>
			<Prompt when={isDirtyIndex !== -1 || CustomerList[selectedCustomer]?._isNew === true} message={t("discardChangesDialog.leaveOverview")} />
			<ResourceLayout
				left={
					<ResourceList
						resources={CustomerList}
						selectedItem={selectedCustomer}
						getListItemText={(Customer: CustomerModel) => {
							return Customer.name + (Customer._isNew || CustomerList[isDirtyIndex]?.id === Customer.id ? "*" : "");
						}}
						resourceClickCallBack={setIndex}
						addItemCallback={addCustomer}
					/>
				}
				right={
					CustomerList.length > 0 ? (
						<CustomerMask
							editEnabled={true}
							customer={CustomerList[selectedCustomer]}
							updateCallback={updateCallback(selectedCustomer)}
							deleteCallback={deleteCallback(selectedCustomer)}
							isDirtyCallback={() => setIsDirtyIndex(selectedCustomer)}
							asDialog={false}
							closeCallback={() => {}}
						/>
					) : (
						<div />
					)
				}
			/>
		</>
	);
}

export default withSnackbar(CustomerOverview);
