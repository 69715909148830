import React, {useContext, useEffect, useRef, useState} from "react";
import DayPlanModel from "../../model/types/DayPlanModel";
import TeamModel from "../../model/types/TeamModel";
import VehicleModel from "../../model/types/basistypes/ressources/VehicleModel";
import TeamDialog from "./Helper/TeamDialog";
import EmployeeModel from "../../model/types/basistypes/ressources/EmployeeModel";
import {getEmployee, getEmployeeForDayPlan} from "../../model/ModelController/Resources/EmployeeController";
import {getVehicle, getVehiclesForDayPlan} from "../../model/ModelController/Resources/VehicleController";
import {addDayPlan, deleteDayPlan} from "../../model/ModelController/Planning/DayPlanController";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import EquipmentModel from "../../model/types/basistypes/ressources/EquipmentModel";
import {getEquipmentForDayPlan} from "../../model/ModelController/Resources/EquipmentController";
import DnDContextWrapper, {createRegisterCallBack, DnDItem} from "./Helper/DndContextWrapper";
import DroppableJSXwrapper from "./Helper/DroppableJSXwrapper";
import DnDItemCard from "./Helper/DnDItemCard";
import EmployeeMask from "../Administration/Resources/Employees/EmployeeMask";
import VehicleMask from "../Administration/Resources/Vehicles/VehicleMask";
import EquipmentMask from "../Administration/Resources/Equipment/EquipmentMask";
import {AppointmentModel, ResourceType} from "../../model/types/basistypes/times/AppointmentModel";
import {
	registerAppointmentsForRangeSnapshotListener,
	registerBlockedResourceListener
} from "../../model/ModelController/times/AppointmentController";
import {validateTeamConfiguration} from "./Helper/Validator";
import {AppBarInfoAreaContext} from "../Sidebar/AppBarInfoAreaContext";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FilterPopover from "../Administration/Resources/Helper/FilterPopover";
import {
	Add,
	Delete,
	Event,
	FileCopy,
	FilterAlt,
	FilterAltOff,
	MoreVert,
	OpenInNew,
	Print,
	Save
} from "@mui/icons-material";
import {Alert, CircularProgress, Dialog, Grid, IconButton, Paper, Typography} from "@mui/material";
import {DailyPlanStyledHeaderPaper, DayPlanStyledPaper} from "../StyledComponents/ComponentStyled/DayPlanStyled";
import {useTranslation} from "react-i18next";
import TeamWorkPlanPDF from "./Helper/TeamDialogHelper/TeamWorkPlanPDF";
import {
	getCurrentDateAsUTCTimestamp,
	getCurrentDateAtStartAsUTCTimestamp,
	localDateToUTCTimestamp,
	localDateToUTCTimestampEnd,
	localDateToUTCTimestampStart,
	utcTimestampToFormattedString,
} from "../../utility/dateUtil";
import useTheme from "@mui/material/styles/useTheme";
import PopupToConfirm from "./Helper/PopupToConfirm";
import {UserContext} from "../Sidebar/UserContext";
import {getPermissionFor} from "../../utility/userPermissionUtil";
import GenericActionBar from "../ActionBar/GenericActionBar";
import {Action} from "../ActionBar/GenericActionBarIconButton";

interface PropsType {
	date?: number;
	dayPlan?: DayPlanModel;
	copyCallback: () => void;
}

function getEmptyDayPlan(Datum: number): DayPlanModel {
	return {
		teams: [],
		date: Datum,
	};
}

function DayPlan(props: any | PropsType) {
	const { enqueueSnackbar } = useSnackbar();
	const showSnackbar = (variant: VariantType, message: string) => {
		enqueueSnackbar(message, { variant });
	};
	const theme = useTheme();
	const userContext = useContext(UserContext);
	const [localDayPlan, setLocalDayPlan] = useState<DayPlanModel>(!props.dayPlan ? getEmptyDayPlan(props.Datum) : props.dayPlan);
	const [firebaseEmployee, setFirebaseEmployee] = useState<EmployeeModel[]>([]);
	const [firebaseVehicle, setFirebaseVehicle] = useState<VehicleModel[]>([]);
	const [firebaseEquipment, setFirebaseEquipment] = useState<EquipmentModel[]>([]);
	const [loaded, setLoaded] = useState(false);
	const [teamOpen, setTeamOpen] = React.useState(false);
	const [clickedIndex, setClickedIndex] = useState(0);
	const [selectedEmployee, setSelectedEmployee] = useState<EmployeeModel>();
	const [selectedVehicle, setSelectedVehicle] = useState<VehicleModel>();
	const [selectedEquipment, setSelectedEquipment] = useState<EquipmentModel>();
	const [isLocked, setIsLocked] = useState<boolean>(localDayPlan?.date < getCurrentDateAsUTCTimestamp());
	const [employeeDialog, setEmployeeDialog] = useState({
		type: "",
		index: 0,
		teamIndex: 0,
		open: false,
	});
	const [vehicleDialog, setVehicleDialog] = useState({
		type: "",
		index: 0,
		teamIndex: 0,
		open: false,
	});
	const [equipmentDialog, setEquipmentDialog] = useState({
		type: "",
		index: 0,
		teamIndex: 0,
		open: false,
	});
	const [popupToConfirmOpen, setPopupToConfirmOpen] = useState(false);
	const [filterPopover, setFilterPopover] = useState({
		employee: false,
		employeeFilterValue: "",
		vehicle: false,
		vehicleFilterValue: "",
		machine: false,
		machineFilterValue: "",
	});

	const { t } = useTranslation();
	const [anchorEl, setAnchor] = useState<Element | null>(null);

	const appbarContext = useContext(AppBarInfoAreaContext);
	appbarContext.updateContent(t("dayPlan.manageTitle") + " " + utcTimestampToFormattedString(props.Datum));

	//number = Id of a resource
	//AppointmentModel[] holds the events corresponding to the resource
	const [eventMap, setEventMap] = useState<Map<number, AppointmentModel[]>>(new Map<number, AppointmentModel[]>());

	const [dndRegister, setDndRegister] = useState<Map<string, DnDItem>>(new Map<string, DnDItem>());
	const registerToDnDDRegister = createRegisterCallBack(dndRegister, setDndRegister);

	const mapref = useRef(eventMap);

	const updateMapRef = (map: Map<number, AppointmentModel[]>) => {
		mapref.current = map;
		setEventMap(map);
	};

	const handleClickOpen = (index: number) => {
		return () => {
			setTeamOpen(true);
			setClickedIndex(index);
		};
	};

	const handleCloseTeamDialog = () => {
		setTeamOpen(false);
	};

	const saveDayPlan = () => {
		addDayPlan(localDayPlan)
			.then((e) => {
				showSnackbar("success", t("changesStored"));
			})
			.catch((e) => {
				showSnackbar("error", e);
			});
	};

	const handleSaveTeamDialog = (newTeamState: TeamModel, index: number) => {
		return () => {
			let copyTeams = [...localDayPlan.teams];
			copyTeams[index] = newTeamState;
			setLocalDayPlan({ ...localDayPlan, teams: copyTeams });
			setTeamOpen(false);
			//Save the Updated Version to the Firebase
			addDayPlan({ ...localDayPlan, teams: copyTeams })
				.then((e) => {
					showSnackbar("success", t("changesStored"));
				})
				.catch((e) => {
					showSnackbar("error", e);
				});
		};
	};

	const buildEventMap = (createdObjects: AppointmentModel[], updateObjects: AppointmentModel[], deletedObjects: AppointmentModel[]) => {
		let newEventMap = new Map(mapref.current);
		for (let createdObject of createdObjects) {
			let currentEntry = newEventMap.get(createdObject.resource.id);
			if (currentEntry) {
				newEventMap.set(createdObject.resource.id, [...currentEntry, createdObject]);
			} else {
				newEventMap.set(createdObject.resource.id, [createdObject]);
			}
		}

		for (let updatedObject of updateObjects) {
			let currentEntry = eventMap.get(updatedObject.resource.id);
			if (currentEntry) {
				let newList = currentEntry.map((entry) => {
					return entry.id === updatedObject.id ? updatedObject : entry;
				});
				newEventMap.set(updatedObject.resource.id, newList);
			} else {
				newEventMap.set(updatedObject.resource.id, [updatedObject]);
			}
		}

		for (let deletedObject of deletedObjects) {
			let currentEntry = eventMap.get(deletedObject.resource.id);
			if (currentEntry) {
				let newList = currentEntry.filter((entry) => {
					return entry.id !== deletedObject.id;
				});
				newEventMap.set(deletedObject.resource.id, newList);
			}
		}

		updateMapRef(newEventMap);
	};

	//initalize EventsForToday
	useEffect(() => {
		let unsubscribeEmployee: any;
		let unsubscribeVehicle: any;
		let unsubscribeEquipment: any;
		let unsubscribeEmployeeBlock: any;
		let unsubscribeVehicleBlock: any;
		let unsubscribeEquipmentBlock: any;
		let fromDate = localDateToUTCTimestampStart(props.Datum);
		let toDate = localDateToUTCTimestampEnd(props.Datum);

		let registerListener = async () => {
			unsubscribeEmployee = await registerAppointmentsForRangeSnapshotListener(ResourceType.EMPLOYEE, fromDate, toDate, buildEventMap);
			unsubscribeVehicle = await registerAppointmentsForRangeSnapshotListener(ResourceType.VEHICLE, fromDate, toDate, buildEventMap);
			unsubscribeEquipment = await registerAppointmentsForRangeSnapshotListener(ResourceType.EQUIPMENT, fromDate, toDate, buildEventMap);
			unsubscribeEmployeeBlock = await registerBlockedResourceListener(ResourceType.EMPLOYEE, toDate, buildEventMap);
			unsubscribeVehicleBlock = await registerBlockedResourceListener(ResourceType.VEHICLE, toDate, buildEventMap);
			unsubscribeEquipmentBlock = await registerBlockedResourceListener(ResourceType.EQUIPMENT, toDate, buildEventMap);
		};

		registerListener();

		return function cleanup() {
			unsubscribeEmployee();
			unsubscribeVehicle();
			unsubscribeEquipment();
			unsubscribeEmployeeBlock();
			unsubscribeEquipmentBlock();
			unsubscribeVehicleBlock();
			updateMapRef(new Map<number, AppointmentModel[]>());
		};
	}, [props.Datum]);

	//initialize Resources
	useEffect(() => {
		setLoaded(false);
		let initializeStore = async () => {
			if (props.dayPlan) {
				setLocalDayPlan(props.dayPlan);
			} else {
				setLocalDayPlan(getEmptyDayPlan(props.Datum));
			}

			let employeeStore: EmployeeModel[];
			let vehicleStore: VehicleModel[];
			let machineStore: EquipmentModel[];

			if (props.dayPlan) {
				employeeStore = await getEmployeeForDayPlan(props.dayPlan);
				vehicleStore = await getVehiclesForDayPlan(props.dayPlan);
				machineStore = await getEquipmentForDayPlan(props.dayPlan);
			} else {
				employeeStore = await getEmployee();
				vehicleStore = await getVehicle();
				machineStore = await getEquipmentForDayPlan(getEmptyDayPlan(getCurrentDateAtStartAsUTCTimestamp()));
			}

			setFirebaseEmployee(employeeStore);
			setFirebaseVehicle(vehicleStore);
			setFirebaseEquipment(machineStore);
			setLoaded(true);
		};

		initializeStore();
	}, [props.Datum, props.dayPlan]);

	const getEventForResource = (Id: number) => {
		let events: AppointmentModel[] | undefined = eventMap.get(Id);
		return (events ? events : []) as AppointmentModel[];
	};

	const resourceIsAvailable = (Id: number) => {
		// if (isLocked) {
		//   return false;
		// }
		let events = getEventForResource(Id);
		//if no entry is found that blocks the Resource
		//the loop won't terminate and we return true
		for (let eventEntry of events) {
			if (!eventEntry.appointmentType?.usableOn || (!eventEntry.appointmentType.usableAfter && !eventEntry.reusable)) {
				return false;
			}
		}
		return true;
	};

	const addTeam = () => {
		let newDayPlan = { ...localDayPlan };
		let nextId = 0;
		let previous = newDayPlan.teams.length > 0 ? newDayPlan.teams[0].id : 0;

		for (let team of newDayPlan.teams) {
			let difference = team.id - previous;

			if (difference === 0 && team.id > 0) {
				nextId = team.id - 1;
				break;
			}

			if (difference > 1) {
				nextId = previous + 1;
				break;
			}

			nextId = team.id + 1;
			previous = team.id;
		}

		let emptyTeam: TeamModel = {
			id: nextId,
			equipment: [],
			tasks: [],
			employees: [],
			vehicles: [],
			constructionSites: [],
			foremans: [],
		};

		if (nextId === 0 && newDayPlan.teams.length > 0) {
			newDayPlan.teams = [emptyTeam, ...newDayPlan.teams];
		} else {
			newDayPlan.teams.splice(emptyTeam.id, 0, emptyTeam);
		}

		setLocalDayPlan(newDayPlan);
	};

	const unlockDayPlan = () => {
		setIsLocked(false);
	};

	useEffect(() => console.log("locked", isLocked), [isLocked]);

  const teamActions = [{
    icon: <Add/>,
    name: "Team",
    tooltip: t("actions.team"),
    callback: addTeam,
    disabled: isLocked,
  },]

  const buildActions = (): Array<Action | any> => {
    const actions: Array<Action | any> = [
      {
        icon: <Save/>,
        name: "Save",
        tooltip: t("actions.save"),
        callback: saveDayPlan,
        disabled: isLocked,
        actionKey: "s"
      },
      {
        icon: <FileCopy/>,
        name: "Copy",
        tooltip: t("actions.copy"),
        callback: props.copyCallback,
      },
      {
        icon: <Delete/>,
        name: "Delete",
        tooltip: t("actions.delete"),
        callback: () => {
          setPopupToConfirmOpen(true);
        },
        disabled: isLocked,
      },
      {
        icon: <Event/>,
        name: "DayPlan",
        tooltip: t("actions.dayPlan"),
        callback: props.chooseAgainCallback,
      },
      {
		icon: <Print />,
		name: "Print",
		tooltip: t("actions.print"),
		printFileName: t("teamWorkPlanPDF.fileName") + utcTimestampToFormattedString(localDayPlan.date) + ".pdf",
		printDocument: <TeamWorkPlanPDF teams={localDayPlan.teams} date={localDateToUTCTimestamp(localDayPlan.date)} />,
		},
		];
		return actions;
	};

	const getTeamItem = (id: number, index: number, title: string, object: any, buttonAction: () => void) => {
		return (
      <Grid xs={12} item container>
        <DnDItemCard
          title={title}
          id={id}
          index={index}
          referencedObject={object}
          deactivateDnD={isLocked}
          actions={buildTeamItemActions(buttonAction)}
          available={resourceIsAvailable(id)}
        />
      </Grid>
    );
	};

	const containsFilterValue = (object: any, filtervalue: string) => {
		let keys = Object.keys(object);

		if (filtervalue === "") {
			return true;
		}

		for (let key of keys) {
			let value = object[key].toString();
			if (value) {
				if (value.includes(filtervalue)) {
					return true;
				}
			}
		}
		return false;
	};

	const buildTeamActions = (index: number) => {
		if (!isLocked) {
			return [
				{
					icon: <OpenInNew />,
					actionText: t("actions.teamDialog"),
					action: handleClickOpen(index),
				},
				{
					icon: <Delete />,
					actionText: t("actions.deleteTeam"),
					action: () => {
						let dayPlan = { ...localDayPlan };
						let teams = localDayPlan.teams;
						teams.splice(index, 1);
						dayPlan.teams = teams;
						setLocalDayPlan(dayPlan);
					},
				},
			];
		} else {
			return [];
		}
	};

	const buildEmployeeActions = (index: number, employee: EmployeeModel) => {
		if (!isLocked) {
			return [
				{
					icon: <MoreVert />,
					actionText: "",
					action: () => {
						setEmployeeDialog({
							open: true,
							index: index,
							teamIndex: -1,
							type: "Store",
						});
						setSelectedEmployee(employee);
					},
				},
			];
		} else {
			return [];
		}
	};

	const buildVehicleActions = (index: number, vehicle: VehicleModel) => {
		if (!isLocked) {
			return [
				{
					icon: <MoreVert />,
					actionText: "",
					action: () => {
						setVehicleDialog({
							open: true,
							index: index,
							teamIndex: -1,
							type: "Store",
						});
						setSelectedVehicle(vehicle);
					},
				},
			];
		} else {
			return [];
		}
	};

	const buildEquipmentActions = (index: number, equipment: EquipmentModel) => {
		if (!isLocked) {
			return [
				{
					icon: <MoreVert />,
					actionText: "",
					action: () => {
						setEquipmentDialog({
							open: true,
							index: index,
							teamIndex: -1,
							type: "Store",
						});
						setSelectedEquipment(equipment);
					},
				},
			];
		} else {
			return [];
		}
	};

	const buildTeamItemActions = (buttonAction: () => void) => {
		if (!isLocked) {
			return [
				{
					icon: <MoreVert />,
					actionText: t("actions.dataMaintenance"),
					action: buttonAction,
				},
			];
		} else {
			return [];
		}
	};

	const checkUserPermissionForUnlock = (): boolean => {
		let dayPlanPermissions = getPermissionFor(userContext.user?.role, "Admin");

		return dayPlanPermissions.createAccess && dayPlanPermissions.updateAccess;
	};

	return localDayPlan ? (
		// @ts-ignore
		<DnDContextWrapper registeredItems={dndRegister}>
			{isLocked && (
				<Alert severity="warning">
					{t("dayPlan.bygone")} {checkUserPermissionForUnlock() ? t("dayPlan.unlock") : ""}
					{checkUserPermissionForUnlock() && (
						<IconButton aria-label="unlock" onClick={unlockDayPlan}>
							<LockOpenIcon />
						</IconButton>
					)}
				</Alert>
			)}
			<Grid container xs={12}>

				{/* Container To Render Teams */}
				<Grid item xs={8}>
					<DayPlanStyledPaper sx={{ backgroundColor: "paper.default" }}>
              <GenericActionBar actions={buildActions()}/>
						{/* Container containing headlines of the teams*/}
						<Grid xs={12} container>
							<Grid xs={3} item>
								<DailyPlanStyledHeaderPaper>

                  <Typography variant={"h6"} align={"center"}>
                      {t("team.title")}
                    </Typography>
                  </DailyPlanStyledHeaderPaper>
                </Grid>
                <Grid xs={3} item>
                  <DailyPlanStyledHeaderPaper>
                    <Typography variant={"h6"} align={"center"}>
                      {t("employee.titlePlural")}
                    </Typography>
                  </DailyPlanStyledHeaderPaper>
                </Grid>
                <Grid xs={3} item>
                  <DailyPlanStyledHeaderPaper>
                    <Typography variant={"h6"} align={"center"}>
                      {t("vehicle.titlePlural")}
                    </Typography>
                  </DailyPlanStyledHeaderPaper>
                </Grid>
                <Grid xs={3} item>
                  <DailyPlanStyledHeaderPaper>
                    <Typography variant={"h6"} align={"center"}>
                      {t("equipment.titlePlural")}
                    </Typography>
                  </DailyPlanStyledHeaderPaper>
                </Grid>
              </Grid>
              {
                /* Generate Grid Container for each Team Containing DnD lists for each item */
                localDayPlan.teams.map((team, index) => {
                  //determine whether the team ist available
								let configValid = validateTeamConfiguration(team, resourceIsAvailable);
								return (
									<Grid
										xs={12}
										container
										component={Paper}
										sx={
											!configValid
												? {
														mt: 1,
														mb: 1,
														pt: 1,
														pb: 1,
														background: theme.palette.paper.error,
												  }
												: {
														mt: 1,
														mb: 1,
														pt: 1,
														pb: 1,
														backgroundColor: "paper.main",
												  }
										}
									>
										{/* Renders the Team Id */}
										<Grid xs={3} item>
											<DnDItemCard
												title={team?.name || t("team.title") + " " + team.id}
												index={team.id}
												id={team.id}
												referencedObject={team}
												actions={buildTeamActions(index)}
												available={true}
												deactivateDnD={true}
											/>
										</Grid>
										{/* Renders a Teams employees*/}
										<Grid xs={3} item>
											<DroppableJSXwrapper
												Id={"Team_" + team.id + "_employee"}
												referencedDnDArray={team.employees}
												referencedUpdateCallback={(employeeList: EmployeeModel[]) => {
													let newTagesPlanState = { ...localDayPlan };
													let deletedForeman = 0;
													for (let foremanIndex in newTagesPlanState.teams[index].foremans) {
														let foreman = newTagesPlanState.teams[index].foremans[foremanIndex];
														let found = employeeList.findIndex((employee) => employee.id === foreman.id);

														if (found < 0) {
															//at this point only one foreman can exist
															newTagesPlanState.teams[index].foremans.splice(parseInt(foremanIndex) - deletedForeman, 1);
															deletedForeman++;
														}
													}


													newTagesPlanState.teams[index].employees = employeeList;
													setLocalDayPlan(newTagesPlanState);
												}}
												dndType={"Employee"}
												registerCallBack={registerToDnDDRegister}
											>
												<Grid item xs={12}>
													{team.employees.map((employee, index) => {
														return [
															getTeamItem(employee.id, index, employee.surname + ", " + employee.name, employee, () => {
																setEmployeeDialog({
																	open: true,
																	index: index,
																	teamIndex: team.id,
																	type: "Team",
																});
																setSelectedEmployee(employee);
															}),
														];
													})}
												</Grid>
											</DroppableJSXwrapper>
										</Grid>
										{/* Renders a Teams vehicles*/}
										<Grid xs={3} item>
											<DroppableJSXwrapper
												Id={"Team_" + team.id + "_vehicle"}
												referencedDnDArray={team.vehicles}
												referencedUpdateCallback={(vehicleList: VehicleModel[]) => {
													let newTagesPlanState = { ...localDayPlan };
													newTagesPlanState.teams[index].vehicles = vehicleList;

													setLocalDayPlan(newTagesPlanState);
												}}
												dndType={"Vehicle"}
												registerCallBack={registerToDnDDRegister}
											>
												<Grid xs={12}>
													{team.vehicles.map((vehicle, index) => {
														return getTeamItem(vehicle.id, index, vehicle.licensePlace, vehicle, () => {
															setVehicleDialog({
																open: true,
																index: index,
																teamIndex: team.id,
																type: "Team",
															});
															setSelectedVehicle(vehicle);
														});
													})}
												</Grid>
											</DroppableJSXwrapper>
										</Grid>
										{/* Renders a Teams machines*/}
										<Grid xs={3}>
											<DroppableJSXwrapper
												Id={"Team_" + team.id + "_machine"}
												referencedDnDArray={team.equipment}
												referencedUpdateCallback={(machineList: EquipmentModel[]) => {
													let newTagesPlanState = { ...localDayPlan };
													newTagesPlanState.teams[index].equipment = machineList;

													setLocalDayPlan(newTagesPlanState);
												}}
												dndType={"Machine"}
												registerCallBack={registerToDnDDRegister}
											>
												<Grid xs={12}>
													{team.equipment.map((machine, index) => {
														return getTeamItem(machine.id, index, machine.producer + "[ " + machine.type + " ]", machine, () => {
															setEquipmentDialog({
																open: true,
																index: index,
																teamIndex: team.id,
																type: "Team",
															});
															setSelectedEquipment(machine);
														});
                          })}
                        </Grid>
                      </DroppableJSXwrapper>
                    </Grid>
                      </Grid>
                  );
                })
              }

              <Grid xs={12}>
                <Paper sx={{backgroundColor: "paper.grey"}}>
                  <Grid item xs={3} container justifyContent={"center"} alignItems={"center"}>
                    <Grid item container
                          justifyContent={"center"}>
                      <GenericActionBar actions={teamActions}/>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            </DayPlanStyledPaper>
        </Grid>
        {/* Container To Render Store */}
        <Grid item container xs={4}>
          <DayPlanStyledPaper>
            {/* Container containing headlines of the Store*/}
            <Grid xs={12} container>
              {/*Grid for "Mitarbeiter" header title */}
              <Grid xs={4} item>
                <DailyPlanStyledHeaderPaper>
                  <Grid item xs={12} container>
                    <Grid item xs={9}>
                      <Typography variant={"h6"} align={"center"}>
                        {t("employee.titlePlural")}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        size={"small"}
                        onClick={(event) => {
                          setFilterPopover({
                            ...filterPopover,
                            employee: true,
                          });
                          setAnchor(event.currentTarget);
                        }}
                      >
												{filterPopover.employeeFilterValue ? <FilterAlt /> : <FilterAltOff />}
											</IconButton>
											<FilterPopover
												open={filterPopover.employee}
												anchor={anchorEl}
												filterValueCallback={(value) =>
													setFilterPopover({
														...filterPopover,
														employeeFilterValue: value,
													})
												}
												onCloseCallback={() => {
													setFilterPopover({
														...filterPopover,
														employee: false,
													});
												}}
											/>
										</Grid>
									</Grid>
								</DailyPlanStyledHeaderPaper>
							</Grid>
							{/*Grid for "Fahrzeug" header title */}
							<Grid xs={4} item>
								<DailyPlanStyledHeaderPaper>
									<Grid item xs={12} container>
										<Grid item xs={9}>
											<Typography variant={"h6"} align={"center"}>
												{t("vehicle.titlePlural")}
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<IconButton
												size={"small"}
												onClick={(event) => {
													setFilterPopover({ ...filterPopover, vehicle: true });
													setAnchor(event.currentTarget);
												}}
											>
												{filterPopover.vehicleFilterValue ? <FilterAlt /> : <FilterAltOff />}
											</IconButton>
											<FilterPopover
												open={filterPopover.vehicle}
												anchor={anchorEl}
												filterValueCallback={(value) =>
													setFilterPopover({
														...filterPopover,
														vehicleFilterValue: value,
													})
												}
												onCloseCallback={() => {
													setFilterPopover({
														...filterPopover,
														vehicle: false,
													});
												}}
											/>
										</Grid>
									</Grid>
								</DailyPlanStyledHeaderPaper>
							</Grid>
							{/*Grid for "Geräte" header title */}
							<Grid xs={4} item>
								<DailyPlanStyledHeaderPaper>
									<Grid item xs={12} container>
										<Grid item xs={9}>
											<Typography variant={"h6"} align={"center"}>
												{t("equipment.titlePlural")}
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<IconButton
												size={"small"}
												onClick={(event) => {
													setFilterPopover({ ...filterPopover, machine: true });
													setAnchor(event.currentTarget);
												}}
											>
												{filterPopover.machineFilterValue ? <FilterAlt /> : <FilterAltOff />}
											</IconButton>
											<FilterPopover
												open={filterPopover.machine}
												anchor={anchorEl}
												filterValueCallback={(value) =>
													setFilterPopover({
														...filterPopover,
														machineFilterValue: value,
													})
												}
												onCloseCallback={() => {
													setFilterPopover({
														...filterPopover,
														machine: false,
													});
												}}
											/>
										</Grid>
									</Grid>
								</DailyPlanStyledHeaderPaper>
							</Grid>
						</Grid>
						{/* Container Holding the Store Lists for our Resources*/}
						<Grid xs={12} container>
							{/* Render all stored Employees */}
							<Grid xs={4} item>
								<DroppableJSXwrapper
									Id={"EmployeeStore"}
									referencedDnDArray={firebaseEmployee}
									referencedUpdateCallback={setFirebaseEmployee}
									dndType={"Employee"}
									registerCallBack={registerToDnDDRegister}
								>
									{loaded ? (
										firebaseEmployee.map((employee, index) => {
											if (!containsFilterValue(employee, filterPopover.employeeFilterValue)) return <div />;

											return (
												<DnDItemCard
													title={employee.surname + ", " + employee.name}
													id={employee.id}
													index={index}
													referencedObject={employee}
													deactivateDnD={!resourceIsAvailable(employee.id) || isLocked}
													actions={buildEmployeeActions(index, employee)}
													available={resourceIsAvailable(employee.id)}
												/>
											);
										})
									) : (
										<CircularProgress />
									)}
								</DroppableJSXwrapper>
							</Grid>
							{/* Render all stored Vehicles */}
							<Grid xs={4} item>
								<DroppableJSXwrapper
									Id={"VehicleStore"}
									referencedDnDArray={firebaseVehicle}
									referencedUpdateCallback={setFirebaseVehicle}
									dndType={"Vehicle"}
									registerCallBack={registerToDnDDRegister}
								>
									{loaded ? (
										firebaseVehicle.map((vehicle, index) => {
											if (!containsFilterValue(vehicle, filterPopover.vehicleFilterValue)) return <div />;

											return (
												<DnDItemCard
													title={vehicle.licensePlace}
													id={vehicle.id}
													index={index}
													referencedObject={vehicle}
													deactivateDnD={!resourceIsAvailable(vehicle.id) || isLocked}
													actions={buildVehicleActions(index, vehicle)}
													available={resourceIsAvailable(vehicle.id)}
												/>
											);
										})
									) : (
										<CircularProgress />
									)}
								</DroppableJSXwrapper>
							</Grid>
							<Grid xs={4} item>
								<DroppableJSXwrapper
									Id={"MachineStore"}
									referencedDnDArray={firebaseEquipment}
									referencedUpdateCallback={setFirebaseEquipment}
									dndType={"Machine"}
									registerCallBack={registerToDnDDRegister}
								>
									{loaded ? (
										firebaseEquipment.map((equipment, index) => {
											if (!containsFilterValue(equipment, filterPopover.machineFilterValue)) return <div />;
											return (
												<DnDItemCard
													title={equipment.producer + "[ " + equipment.type + " ]"}
													index={index}
													id={equipment.id}
													referencedObject={equipment}
													deactivateDnD={!resourceIsAvailable(equipment.id) || isLocked}
													actions={buildEquipmentActions(index, equipment)}
													available={resourceIsAvailable(equipment.id)}
												/>
											);
										})
									) : (
										<CircularProgress />
									)}
								</DroppableJSXwrapper>
							</Grid>
						</Grid>
					</DayPlanStyledPaper>
				</Grid>
				{/* Modals and Popups */}
				<div>
					{teamOpen ? (
						<TeamDialog
							team={localDayPlan?.teams[clickedIndex]}
							index={clickedIndex}
							open={teamOpen}
							workPlanDate={localDayPlan.date}
							handleSave={handleSaveTeamDialog}
							handleClose={handleCloseTeamDialog}
						/>
					) : (
						<div />
					)}
					{employeeDialog.open && selectedEmployee ? (
						<Dialog fullScreen open={employeeDialog.open}>
							<EmployeeMask
								employee={selectedEmployee}
								asDialog={true}
								editEnabled={true}
								closeCallback={() => {
									setEmployeeDialog({ ...employeeDialog, open: false });
								}}
								deleteCallback={() => {
									if (employeeDialog.type === "Team") {
										let newDayPlan = { ...localDayPlan };
										let employees = newDayPlan.teams[employeeDialog.teamIndex].employees;
										employees.splice(employeeDialog.index, 1);
										newDayPlan.teams[employeeDialog.teamIndex].employees = employees;

										setLocalDayPlan(newDayPlan);
									} else {
										let firebaseBucketEmployee = [...firebaseEmployee];
										firebaseBucketEmployee.splice(employeeDialog.index, 1);
										setFirebaseEmployee(firebaseBucketEmployee);
									}
								}}
								updateCallback={(employee) => {
									if (employeeDialog.type === "Team") {
										let newDayPlan = { ...localDayPlan };
										newDayPlan.teams[employeeDialog.teamIndex].employees[employeeDialog.index] = employee;
										setLocalDayPlan(newDayPlan);
									} else {
										let firebaseBucketEmployees = [...firebaseEmployee];
										firebaseBucketEmployees[employeeDialog.index] = employee;
										setFirebaseEmployee(firebaseBucketEmployees);
									}
								}}
							/>
						</Dialog>
					) : (
						<div />
					)}
					{vehicleDialog.open && selectedVehicle ? (
						<Dialog fullScreen open={vehicleDialog.open}>
							<VehicleMask
								vehicle={selectedVehicle}
								asDialog={true}
								editEnabled={true}
								closeCallback={() => {
									setVehicleDialog({ ...vehicleDialog, open: false });
								}}
								deleteCallback={() => {
									if (vehicleDialog.type === "Team") {
										let newDayPlan = { ...localDayPlan };
										let vehicles = newDayPlan.teams[vehicleDialog.teamIndex].vehicles;
										vehicles.splice(vehicleDialog.index, 1);
										newDayPlan.teams[vehicleDialog.teamIndex].vehicles = vehicles;
										setLocalDayPlan(newDayPlan);
									} else {
										let firebaseBucketVehicles = [...firebaseVehicle];
										firebaseBucketVehicles.splice(vehicleDialog.index, 1);
										setFirebaseVehicle(firebaseBucketVehicles);
									}
								}}
								updateCallback={(vehicle) => {
									debugger;
									if (vehicleDialog.type === "Team") {
										let newDayPlan = { ...localDayPlan };
										newDayPlan.teams[vehicleDialog.teamIndex].vehicles[vehicleDialog.index] = vehicle;
										setLocalDayPlan(newDayPlan);
									} else {
										let firebaseBucketVehicles = [...firebaseVehicle];
										firebaseBucketVehicles[vehicleDialog.index] = vehicle;
										setFirebaseVehicle(firebaseBucketVehicles);
									}
								}}
							/>
						</Dialog>
					) : (
						<div />
					)}
					{equipmentDialog.open && selectedEquipment ? (
						<Dialog fullScreen open={equipmentDialog.open}>
							<EquipmentMask
								equipment={selectedEquipment}
								asDialog={true}
								editEnabled={true}
								closeCallback={() => {
									setEquipmentDialog({ ...equipmentDialog, open: false });
								}}
								deleteCallback={() => {
									if (equipmentDialog.type === "Team") {
										let newDayPlan = { ...localDayPlan };
										let machines = newDayPlan.teams[equipmentDialog.teamIndex].equipment;
										machines.splice(equipmentDialog.index, 1);
										newDayPlan.teams[equipmentDialog.teamIndex].equipment = machines;
										setLocalDayPlan(newDayPlan);
									} else {
										let firebaseBucketMachines = [...firebaseEquipment];
										firebaseBucketMachines.splice(equipmentDialog.index, 1);
										setFirebaseEquipment(firebaseBucketMachines);
									}
								}}
								updateCallback={(machine) => {
									if (equipmentDialog.type === "Team") {
										let newDayPlan = { ...localDayPlan };
										newDayPlan.teams[equipmentDialog.teamIndex].equipment[equipmentDialog.index] = machine;
										setLocalDayPlan(newDayPlan);
									} else {
										let firebaseBucketMachines = [...firebaseEquipment];
										firebaseBucketMachines[equipmentDialog.index] = machine;
										setFirebaseEquipment(firebaseBucketMachines);
									}
								}}
							/>
						</Dialog>
					) : (
						<div />
					)}
					<PopupToConfirm
						open={popupToConfirmOpen}
						title={t("dayPlan.DeleteDialog.title")}
						text={t("dayPlan.DeleteDialog.text")}
						confirmButtonLabel={t("dayPlan.DeleteDialog.confirmButtonLabel")}
						cancelButtonLabel={t("dayPlan.DeleteDialog.cancelButtonLabel")}
						actionOnConfirm={() => {
							deleteDayPlan(localDayPlan)
								.then(() => {
									showSnackbar("success", t("dayPlan.DeleteDialog.success"));
									props.chooseAgainCallback();
									setPopupToConfirmOpen(false);
								})
								.catch((e) => {
									showSnackbar("error", t("dayPlan.DeleteDialog.error"));
									setPopupToConfirmOpen(false);
								});
						}}
						actionOnCancel={() => {
							setPopupToConfirmOpen(false);
						}}
					/>
				</div>
			</Grid>
		</DnDContextWrapper>
	) : (
		<CircularProgress />
	);
}

export default withSnackbar(DayPlan);
